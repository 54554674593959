<template>
  <div>
    <div class="content_area" v-loading="loading">
      <div class="title">
        <div class="titleText">拼箱运价详情</div>
        <div>
          <el-button
            type="success"
            plain
            size="small"
            @click="showDialog"
            v-if="USER_INFO"
            >一键复制</el-button
          >
          <!-- <el-button
            class="booking"
            type="success"
            size="small"
            v-if="USER_INFO&&USER_INFO.user_type==1"
            @click="handleBookingSpace"
            :loading="loading"
            >快速订舱</el-button
          > -->
        </div>
      </div>
      <div class="mainbox">
        <div class="header">
          <div class="isopen" v-if="header.auth_type == 1 && USER_INFO.user_type == 2">直客</div>
          <div style="display: flex;">
            <img src="https://ym-attached-file.oss-cn-shanghai.aliyuncs.com/miniProgram/lineIcon.png" alt="" style="margin-left: 5px; margin-right: 15px; height: 171px;">
            <div class="heraderTop">
              <div class="div1">
                <div class="portEn" :title="header.from_port_name_en | textFormat">
                  {{ header.from_port_name_en | textFormat }}
                </div>
                <div class="portZh" :title="header.from_port_name_zh | textFormat">
                  {{ header.from_port_name_zh | textFormat }}
                </div>
              </div>
              <div class="div2 myStyle_jt">
                <div>
                  <span
                    v-if="header.trans_port_name_en"
                    :title="header.trans_port_name_en | textFormat"
                    class="trans_port"
                    >{{ header.trans_port_name_en | textFormat }} 转</span
                  >
                  <span v-else>直达</span>
                </div>
                <!-- <div class="line"><span class="jiantou">></span></div> -->
                <!-- <img   src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/ymp/rightarrow.png" alt=""> -->
                <!--            隐藏航线-->
                <!--            <div :title="header.shipping_line_name_cn" class="trans_port">{{ header.shipping_line_name_cn | textFormat }}</div>-->
              </div>
              <div class="div3">
                <div class="portEn" :title="header.dest_port_name_en | textFormat">
                  {{ header.dest_port_name_en | textFormat }}
                </div>
                <div class="portZh" :title="header.dest_port_name_zh | textFormat">
                  {{ header.dest_port_name_zh | textFormat }}
                </div>
              </div>
            </div>
          </div>
          <div class="heraderButtom">
            <div class="div1">
              船期:
              <span :title="header.shipping_date | weekDaysFormat">{{
                header.shipping_date | weekDaysFormat
              }}</span>
            </div>
            <div class="div2">
              航程:
              <span :title="header.days | daysFormat">{{
                header.days | daysFormat
              }}</span>
            </div>
            <div class="div3">
              有效期至:
              <span>{{ header.validity_end | secondFormat("LL") }}</span>
            </div>
          </div>
          <div class="heraderButtom">
            <div class="div4">
              备注:
              <span :title="header.remarks">{{
                header.remarks | textFormat
              }}</span>
            </div>
            
          </div>
          <div class="fleet" v-if="schedulingList && schedulingList.length && USER_INFO.user_type == 2">
            <p class="seaTitle">最新船班
              <span class="fr more" @click="goMore">
                <i class="el-icon-date"></i> 危拼航线排期
              </span>
            </p>
            <div class="fleetlist">
              <div class="item">
                <div class="label">预计开航日期</div>
                <div class="label">属性</div>
                <div class="label">船公司</div>
                <div class="label">是否满货</div>
              </div>
              <div class="item" v-for="item in schedulingList" :key="item.id">
                <div class="label">{{ item.etd_time | secondFormat('L') }}</div>
                <div class="label">{{item.dangerous_remarks | textFormat}}</div>
                <div class="label">{{ item.shipping_company_shot_name | textFormat }}</div>
                <div class="label">
                  <span v-if="item.is_full == 0">未满货</span>
                  <span v-if="item.is_full == 1" style="color: red;">已满货</span>  
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cargoInfo">
          <div class="seaMoney">
            <p class="seaTitle">货物信息</p>
            <div class="searchBox">
              <el-row>
                <el-col :span="5" class="flex">
                  <span>体积（CBM）：</span>
                  <el-input
                    style="width: 120px"
                    type="number"
                    placeholder=""
                    size="small"
                    v-model="volume"
                  >
                  </el-input>
                </el-col>
                <el-col :span="5" class="flex">
                  <span>重量（KGS）：</span>
                  <el-input
                    style="width: 120px"
                    type="number"
                    placeholder=""
                    size="small"
                    v-model="weight"
                  >
                  </el-input>
                </el-col>
                <el-col :span="5" class="flex">
                  <span>件数：</span>
                  <el-input
                    style="width: 120px"
                    type="number"
                    placeholder=""
                    size="small"
                    v-model="number"
                  >
                  </el-input>
                </el-col>
                <el-col :span="5" style="line-height: 32px">
                  计量标准（RT）：{{ MeasurementStandard }}
                </el-col>
                <el-col :span="4">
                  <el-button type="success" size="small" @click="recalculate"
                    >重新计算</el-button
                  >
                </el-col>
              </el-row>
            </div>
          </div>

          <div class="moneyList">
            <p class="seaTitle">本地及海外费用明细</p>
            <div class="seaItem">
              <div class="itemTitleBox">
                <div>
                  <img src="https://ym-attached-file.oss-cn-shanghai.aliyuncs.com/miniProgram/ship2.png" style="width:30px;height:30px;margin-right:10px" alt="">
                  <span class="itemTitle">海运费：</span>
                  <span class="itemMOney"
                    >USD
                    <span style="color: red" v-if="header.price_lcl_dangerous < 0">
                      ({{ seaTotal.toFixed() }})</span
                    >
                    <span v-else>{{ seaTotal.toFixed() }}</span>
                  </span>
                 <span class="itemTips" v-if="USER_INFO && USER_INFO.user_type == 2">仅显示市场总价</span>
                </div>
              </div>
              <div class="itemContent">
                <div class="tableLine tableTitle">
                  <div class="table1"></div>
                  <div class="table2">币种</div>
                  <div class="table3">计量单位</div>
                  <div class="table4" style="width: 200px;">单价</div>
                  <div class="table5">数量</div>
                </div>
                <div class="tableLine">
                  <div class="table1">海运费</div>
                  <div class="table2">USD</div>
                  <div class="table3">CBM</div>
                  <div class="table4" style="width: 200px;">
                    <template v-if="USER_INFO && USER_INFO.user_type == 2">
                      <div class="unit">
                        市场价 USD 
                        <div
                          class="costItemContent"
                          style="color: red"
                          v-if="header.price_lcl_dangerous < 0"
                        >
                          ({{ Math.abs(header.price_lcl_dangerous).toFixed() | textFormat }})
                        </div>
                        <div class="costItemContent" v-else>
                          {{ Number(header.price_lcl_dangerous).toFixed() | textFormat }}
                        </div>
                      </div>
                      <div class="unit">
                        {{ header.auth_type == 1 ? '直客价':'最低价' }} USD
                        <div
                          class="costItemContent"
                          style="color: red"
                          v-if="header.price_lcl_general < 0"
                        >
                          ({{ Math.abs(header.price_lcl_general).toFixed() | textFormat }})
                        </div>
                        <div class="costItemContent" v-else>
                          {{ Number(header.price_lcl_general).toFixed() | textFormat }}
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <div
                        class="costItemContent"
                        style="color: red"
                        v-if="header.price_lcl_dangerous < 0"
                      >
                        ({{ Math.abs(header.price_lcl_dangerous).toFixed() | textFormat }})
                      </div>
                      <div class="costItemContent" v-else>
                        {{ Number(header.price_lcl_dangerous).toFixed() | textFormat }}
                      </div>
                    </template>
                  </div>
                  <div class="table5">
                    {{ MeasurementStandard }}
                  </div>
                </div>
              </div>
            </div>
            <div class="text-mone"></div>
    <!--        备注：{{ header.remarks }}-->

            <!-- <costComp :costList='costList'   @open_close="open_close" ></costComp> -->

            <div class="seaItem" v-for="(item, index) in costList" :key="index">
              <div class="itemTitleBox">
                <div>
                  <img style="width:30px;height:30px;margin-right:10px" src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/ymp/appcCar.png" alt="">
                  <!-- <i class="el-icon-ship"></i> -->
                  <span class="itemTitle">{{ item.group_name }}：</span>
                  <span
                    class="itemMOney"
                    v-for="(it, id) in item.group_total_price"
                    :key="id"
                  >
                    {{ id }}
                    <span>{{ item.group_total_price[id].toFixed(2) }}； </span></span
                  >
                  <span class="itemTips"></span>
                </div>
                <div class="btn">
                  <el-button type="success" size="small" @click="exportFee" style="margin-right:20px" 
                    v-if="item.group_name == '目的港费用' &&  USER_INFO.user_type == 2"
                    >导出目的港费用</el-button
                  >
                  <span v-if="item.open" @click="item.open = false"
                    >收起 <i class="el-icon-arrow-down"></i
                  ></span>
                  <span @click="openList(item)" v-else
                    >展开 <i class="el-icon-arrow-right"></i
                  ></span>
                </div>
              </div>
              <div class="itemContent" v-if="item.open">
                <div class="tableLine tableTitle">
                  <div class="table1"></div>
                  <div class="table2">币种</div>
                  <div class="table3">计量单位</div>
                  <div class="table4">单价</div>
                  <div class="table5">金额</div>
                  <div class="table6">备注</div>
                </div>
                <div class="unData" v-if="item.data_list.length == 0">暂无{{item.group_name}}</div>
                <div class="tableLine" v-for="(it, id) in item.data_list" :key="id" v-else>
                  <div class="table1">{{ it.name_zh }}</div>
                  <div class="table2">{{ it.code }}</div>
                  <div class="table3" >{{ (it.price==0.01)&&it.measure=='SET' ? '':it.measure }}</div>
                  <!-- 单价 -->
                  <div class="table4">
                    <div v-if="it.measure != '箱' && it.measure != 'GP'">
                      <span v-if="it.price==0.01"></span>
                      <span v-else>{{ it.price.toFixed(2)  }} {{it.lowest_price?',':''}} </span>
                      <span style="padding-left: 5px" v-if="it.lowest_price">MINI:{{ it.lowest_price }}</span>
                    </div>
                    <div v-else>
                      <div class="costItemContent">
                        {{ Number(it.GP20).toFixed(2) }}
                        <span>/20GP</span>
                      </div>
                      <div class="costItemContent">
                        {{ Number(it.GP40).toFixed(2) }}
                        <span>/40GP</span>
                      </div>
                      <div class="costItemContent">
                        {{ Number(it.HC40).toFixed(2) }}
                        <span>/40HQ</span>
                      </div>
                    </div>
                  </div>
                  <div class="table5" v-if="it.measure != '箱' && it.measure != 'GP'" >
                    <span v-if="it.price==0.01"> {{it.lowest_price?it.lowest_price:''}} </span>
                    <span v-else>{{ it.total_price.toFixed(2) }}</span>
                  </div>
                  <div class="table5" v-else >
                    <span >{{ it.total_price.toFixed(2) }}</span>
                  </div>
                  <div class="table6">{{ it.remarks }}</div>
                </div>
              </div>
              <!-- <baseInfo v-if="item.open && item.plan_remarks_list.length" :list="item.plan_remarks_list"></baseInfo> -->
              <baseInfo v-if="(item.open && item.has_pod_fee == 1 && item.pod_fee_remarks.length) || (item.open && item.plan_remarks_list.length)" :list="item.has_pod_fee == 1 ? item.pod_fee_remarks : item.plan_remarks_list"></baseInfo>
              <!-- <div class="text-mone" v-for="(item1, index1) in item.plan_remarks_list" :key="index1">{{item1}}</div> -->
            </div>
            <el-alert
              title="订舱前请确认好准确的数据，订舱后因数据不对或其他任何原因退关都会产生空舱费。"
              :closable="false"
              type="warning"
              show-icon
            >
            </el-alert>
            <el-card shadow="never" class="remarkBox" v-if="USER_INFO&&USER_INFO.user_type==2">
              <div slot="header" class="clearfix">
                <span>分享记录</span>
              </div>
              <div style="width:800px">
                    <el-input 
                      size="small" 
                      v-model="share_form.name" 
                      class="kuinput"
                      placeholder="请输入客户名称"
                    >
                    </el-input>
                    <el-input
                      type="textarea"
                      :rows="4"
                      placeholder="输入客户信息，以便之后查询"
                      maxlength="100"
                      show-word-limit
                      v-model="share_form.remarks"
                    >
                    </el-input>
                    <div style="text-align:left;margin-top:15px">
                        <el-button type="primary" size="small" :loading="save_loading" style="width: 230px; background:#91BE42;border-color:#91BE42" @click="handleSave">保存</el-button>
                    </div>
                    <!-- <el-divider><span class="divider_span">最近分享记录（仅显示最近10条）</span></el-divider>
                    <div class="r_list_box">
                        <div class="r_list_item" v-for="(item,i) in remarkArr.slice(0,10)" :key='i'>
                            <div class="msg">{{item.info}}</div>
                            <div class="tt">{{item.time_info | secondFormat("LL")   }}</div>
                        </div>
                    </div> -->
                  </div>
            </el-card>
          </div>
        </div>
      </div>
      <el-dialog
        title="选择需要复制的内容"
        :visible.sync="dialogVisible"
        width="400px"
        :before-close="handleClose"
      >
        <div>
          <div class="chooseItem">
              <div class="itemtitle">基本信息</div>
              <div class="handleBox">
                <el-switch class="switch" v-model="check_base" active-color="#91be42" inactive-color="#ccc" ></el-switch>
              </div>
          </div>

          <div
            class="chooseItem"
            v-for="(item, index) in costList"
            :key="index"
          >
            <div class="itemtitle">{{ item.group_name }}</div>
            <div class="handleBox">
              <el-switch
                class="switch"
                v-model="item.canCopy"
                active-color="#91be42"
                inactive-color="#ccc"
              >
              </el-switch>
            </div>
          </div>

          <div class="chooseItem">
              <div class="itemtitle">订舱提示</div>
              <div class="handleBox">
                <el-switch class="switch" v-model="check_tips" active-color="#91be42" inactive-color="#ccc" ></el-switch>
              </div>
          </div>

        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false" size="small"
            >取 消</el-button
          >
          <el-button type="primary" class="booking" size="small" @click="copy"
            >确 定</el-button
          >
        </span>
      </el-dialog>
      <!-- <div class="content" v-loading="loading">
        <div class="item">
          <div class="costTitle">海运费</div>
          <div class="line"></div>
          <div class="costContent">
            <div class="costItem">
              <div class="costItemTitle">运费单价</div>
              <div
                class="costItemContent"
                style="color: red"
                v-if="header.price_lcl_dangerous < 0"
              >
                ({{ Math.abs(header.price_lcl_dangerous) | textFormat }})<span
                  >USD/CBM</span
                >
              </div>
              <div class="costItemContent" v-else>
                {{ header.price_lcl_dangerous | textFormat
                }}<span>USD/CBM</span>
              </div>
            </div>
            <div class="constItem2">
              IMO surcharge {{ header.imo_surcharge }} {{ header.imo_unit
              }}<span v-if="header.imo_mini"
                >， {{ header.imo_mini }}MINI.</span
              >
            </div>
          </div>
        </div>
        <div class="item" v-for="(item, index) in costList" :key="index">
          <div v-show="index == 0">
            <div class="costTitle">{{ item.group_name }}</div>
            <div class="line"></div>
            <div
              class="costContent"
              v-for="(it, ind) in item.data_list"
              :key="'a' + ind"
            >
              <div
                class="costItem"
                v-if="it.measure != '箱' && it.measure != 'GP'"
               >
                <div style="width: 60%">
                  <div>{{ it.name_en | priorFormat(it.name_zh, LOCALE) }}</div>
                  <div v-if="it.remarks" class="itRemarks">
                    {{ it.remarks }}
                  </div>
                </div>
                <div>
                  <div class="costItemContent">
                    {{ Number(it.price).toFixed(2) }}
                    <span>{{ it.code }}/ {{ it.measure }}</span>
                  </div>
                  <div v-if="it.lowest_price">MINI:{{ it.lowest_price }}</div>
                </div>
              </div>
              <div class="costItem" v-else>
                <div style="width: 60%">
                  <div>{{ it.name_en | priorFormat(it.name_zh, LOCALE) }}</div>
                  <div v-if="it.remarks" class="itRemarks">
                    {{ it.remarks }}
                  </div>
                </div>
                <div style="text-align: right">
                  <div class="costItemContent">
                    {{ Number(it.GP20).toFixed(2) }}
                    <span>{{ it.code }}/20GP</span>
                  </div>
                  <div class="costItemContent">
                    {{ Number(it.GP40).toFixed(2) }}
                    <span>{{ it.code }}/40GP</span>
                  </div>
                  <div class="costItemContent">
                    {{ Number(it.HC40).toFixed(2) }}
                    <span>{{ it.code }}/40HC</span>
                  </div>
                  <div v-if="it.lowest_price">MINI:{{ it.lowest_price }}</div>
                </div>
              </div>
            </div>
          </div>
          <div v-show="index > 0 && IS_LOGIN">
            <div class="costTitle">{{ item.group_name }}</div>
            <div class="line"></div>
            <div
              class="costContent"
              v-for="(it, ind) in item.data_list"
              :key="'a' + ind"
            >
              <div
                class="costItem"
                v-if="it.measure != '箱' && it.measure != 'GP'"
              >
                <div style="width: 60%">
                  <div>{{ it.name_en | priorFormat(it.name_zh, LOCALE) }}</div>
                  <div v-if="it.remarks" class="itRemarks">
                    {{ it.remarks }}
                  </div>
                </div>
                <div>
                  <div class="costItemContent">
                    {{ Number(it.price).toFixed(2) }}
                    <span>{{ it.code }}/ {{ it.measure }}</span>
                  </div>
                  <div v-if="it.lowest_price">MINI:{{ it.lowest_price }}</div>
                </div>
              </div>
              <div class="costItem" v-else>
                <div style="width: 60%">
                  <div>{{ it.name_en | priorFormat(it.name_zh, LOCALE) }}</div>
                  <div v-if="it.remarks" class="itRemarks">
                    {{ it.remarks }}
                  </div>
                </div>
                <div style="text-align: right">
                  <div class="costItemContent">
                    {{ Number(it.GP20).toFixed(2) }}
                    <span>{{ it.code }}/20GP</span>
                  </div>
                  <div class="costItemContent">
                    {{ Number(it.GP40).toFixed(2) }}
                    <span>{{ it.code }}/40GP</span>
                  </div>
                  <div class="costItemContent">
                    {{ Number(it.HC40).toFixed(2) }}
                    <span>{{ it.code }}/40HC</span>
                  </div>
                  <div v-if="it.lowest_price">MINI:{{ it.lowest_price }}</div>
                </div>
              </div>
            </div>
          </div>
          <div v-show="index == 2 && IS_LOGIN" class="item2Text">
            订舱前请确认好准确的数据，订舱后因数据不对或其他任何原因退关都会产生空舱费。
          </div>
        </div>


        <div class="pageHint">
          <i class="el-icon-warning" style="color: red; font-size: 18px"></i
          >订舱前请确认好准确的数据，订舱后因数据不对或其他任何原因退关都会产生空舱费。
        </div>
        <div v-if="!IS_LOGIN" class="goLogin">
          <span @click="handleLogin" class="point">点击登录，查看更多</span>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import costComp from "~bac/components/ymp/costComp";
import baseInfo from "~ymp/components/common/baseInfo";
export default {
  name: "fullfreightSearchDetails",
  components:{
    baseInfo,
    costComp
  },
  data() {
    return {
      check_base:true,
      check_tips:true,
      header: {},
      loading: false,
      dialogVisible: false,
      costList: [],
      schedulingList: [],
      value: false,
      volume: 0,
      weight: 1000,
      number: 1,
      seaTotal: 0,
      MeasurementStandard:0,
      save_loading:false,
      share_form:{
        name:"",
        remarks:""
      }
    };
  },
  mounted() {
    if (this.$route.query.min) {
      this.volume = this.$route.query.min;
    }
    this.$log({
      type: 2,
      topic: "ymp.inquiry",
      id: "ymp.inquiry.search.detail",
      content: JSON.stringify({
        id: this.$route.query.id || '',
      }),
    });
    this.getHeader();

  },
  filters:{
    my_Fixed(value){
      let num=0
      if(value){
        num=Number(value).toFixed(2)
      }
      return num
    }
  },
  watch:{
    volume:function (value){ // 体积
      // MeasurementStandard
      this.num_stand()

    },
    weight:function (value){ // 重量
      this.num_stand()
    },
  },
  computed: {
    // 计算属性的 getter
    // seaTotal: function () {
    //   // `this` 指向 vm 实例
    //   let x = (this.volume> this.weight/1000) ?this.volume:this.weight/1000
    //   let y = Math.abs(this.header.price_lcl_dangerous)
    //   return x*y
    // }
  },
  methods: {
    async handleSave(){
      if(!this.share_form.name){
        this.$message.warning("请输入客户名称")
        return
      }
      let arr=[
        {
          price:this.header.price_lcl_dangerous < 0?Math.abs(this.header.price_lcl_dangerous).toFixed():Number(this.header.price_lcl_dangerous).toFixed(),
          code:"USD",
          unit:"CBM"
        }
      ]
      let params={
        freight_type:2,
        from_port:this.header.from_port,
        trans_port:this.header.transshipment_port||0, 
        dest_port:this.header.dest_port,
        shipping_company:this.header.shipping_company_code,//船公司id
        historical_price:JSON.stringify(arr),//历史单价
        client:this.share_form.name,//客户
        sales_id:this.USER_INFO.id,//销售id
        remarks:this.share_form.remarks//备注
      }
      
      console.log('paramsparams',params);
      this.save_loading=true
      await this.$store.dispatch("API_freight/freight_share_save",params)
      this.share_form={
        name:"",
        remarks:""
      }
      this.save_loading=false
      this.$message.success("保存成功")
    },
    // 计量标准
    num_stand(){
      let volue=this.volume
      let weight=this.weight/1000
      if(volue>weight){
        this.MeasurementStandard=Number(volue).toFixed(2)
      }else {
        this.MeasurementStandard=Number(weight).toFixed(2)
      }
    },
    // 子组件  -打开关闭
    open_close(e){
      this.costList[e.key].open=!this.costList[e.key].open
      this.costList=JSON.parse(JSON.stringify(this.costList))
    },
    openList(item) {
      if (!this.IS_LOGIN) {
        return this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN", {
          waitTodo: () => {
            item.open =true
          },
        });
      } else {
        item.open =true
      }
    },
    goMore(){
      let url = this.$router.resolve({
        path: "/freightTools",
        query: {
          toolsType: 4
        },
      });
      window.open(url.href, "_blank");
    },
    showDialog() {
      this.$log({
        type: 1,
        topic: "ymp.inquiry",
        id: "ymp.inquiry.search.detail.copy",
        content: JSON.stringify({
          id: this.$route.query.id || '',
        }),
      });
      if (this.USER_INFO.company_bind_status != 0) {
        this.dialogVisible = true;
      } else {
        this.$router.push({
          path: "/console/company/basic",
          query: {},
        });
      }
    },
    copy() {
      var port =
        `${
          this.header.from_port_name_en || this.header.from_port_name_zh || "-"
        } - ${
          this.header.dest_port_name_en || this.header.dest_port_name_zh || "-"
        }` + "\n";
        if(this.check_base){
          port = port + (this.header.transshipment_port ? "中转 " : "直达 ");
          if (this.header.transshipment_port) {
            port =
              port +
              `${
                this.header.trans_port_name_en ||
                this.header.trans_port_name_zh ||
                "-"
              } `;
          }
          port = port + "\n";

          if (this.header.price_lcl_dangerous >= 0) {
            port =
              port +
              `海运费：${this.header.code}${this.header.price_lcl_dangerous}/CBM 注：${this.header.remarks}` +
              ",有效至：" +
              this.$moment(this.header.validity_end * 1000).format("MM-DD") +
              "\n";
          } else {
            port =
              port +
              `海运费：${this.header.code}（${Math.abs(
                this.header.price_lcl_dangerous
              )}）/CBM 注：${this.header.remarks}` +
              ",有效至：" +
              this.$moment(this.header.validity_end * 1000).format("MM-DD") +
              "\n";
          }

          port = port + `航程：${this.header.days ? this.header.days : 0} 天，船期：`;
          this.header.week.forEach((ee, vv) => {
            port = port + ee;
            if (vv < this.header.week.length - 1) {
              port = port + ",";
            }
          });
          port = port + "\n"
        }

      let star = "";
      if (this.costList.length) {
        this.costList.forEach((res, index) => {
          if (res.data_list.length && res.canCopy) {
            // star = star + "\n";
            // star = star + "\n";
            star = star + "=========================";
            star = star + "\n";
            star = star + "【" + res.group_name + "】" + "\n";
            res.data_list.forEach((ee) => {
              
              if (ee.unit == 2) {
                star =
                  star +
                  `-- ${ee.name_zh || ee.name_en || "-"}：${Number(
                    ee.GP20
                  ).toFixed(2)} ${ee.code}/20GP,${Number(ee.GP40).toFixed(2)} ${
                    ee.code
                  }/40GP,${Number(ee.HC40).toFixed(2)} ${ee.code}/40HQ`;

                if (ee.lowest_price) {
                  star = star + `,MINI：${ee.lowest_price}`;
                }
                if (ee.remarks) {
                  star = star + `,*${ee.remarks}`;
                }
                star = star + "\n";
              } else {
                if((ee.price==0.01)&&ee.measure=='SET'){
                   star =star +
                  `-- ${ee.name_zh || ee.name_en || "-"}：`

                }else if((ee.price==0.01)&&ee.measure!='SET'){
                  star =star +
                  `-- ${ee.name_zh || ee.name_en || "-"}：${ee.code}/${ee.measure} ,`;
                }else{
                  star =star +
                  `-- ${ee.name_zh || ee.name_en || "-"}：${(ee.price==0.01)?0:Number(
                    ee.price
                  ).toFixed(2)} ${ee.code}/${ee.measure} ,`;
                }

               
                if (ee.lowest_price) {
                  star = star + `MINI:${ee.lowest_price}`;
                }
                if(ee.lowest_price && ee.remarks){
                  star = star + `,`
                }
                if (ee.remarks) {
                  star = star + `*${ee.remarks}`;
                }
                star = star + "\n";
              }
            });
            if(res.plan_remarks_list.length){
              star = star + "备注：";
              res.plan_remarks_list.forEach(element => {
                  star = star+element+'\n'
              });
            }
            
          }
        });
      }

      let lastMsg = ""
      if(this.check_tips){
        lastMsg = "\n" + "订舱前请确认好准确的数据，订舱后因数据不对或其他任何原因退关都会产生空舱费。\n可自主登录运抵达（www.yundida.com）查询危拼运价。";
      }
      var content = port.concat(star, lastMsg);

      // 使用textarea支持换行，使用input不支持换行
      const textarea = document.createElement("textarea");
      textarea.value = content;
      document.body.appendChild(textarea);

      textarea.select();
      if (document.execCommand("copy")) {
        document.execCommand("copy");
        this.$message({
          message: "您选择的费用信息已经复制到剪贴板",
          type: "success",
        });
        this.dialogVisible = false;
      }
      document.body.removeChild(textarea);
      this.dialogVisible = false
    },

    handleClose() {
      this.dialogVisible=false
    },
    async getHeader() {
      this.loading = true;
      let params = {
        freight_id: this.$route.query.id,
        booking_space: 1,
      };
      let result = await Promise.all([
        this.$store.dispatch("API_freight/getFreightHeader", params)
      ]);
      
      if (result[0].success) {
        let data = result[0].data;
        data.week = [];
        data.abs = Math.abs(data.price_lcl_dangerous);
        data.shipping_date_arr = data.shipping_date.split(",");
        data.shipping_date_arr.forEach((ee) => {
          if (ee == 1) {
            data.week.push("周一");
          }
          if (ee == 2) {
            data.week.push("周二");
          }
          if (ee == 3) {
            data.week.push("周三");
          }
          if (ee == 4) {
            data.week.push("周四");
          }
          if (ee == 5) {
            data.week.push("周五");
          }
          if (ee == 6) {
            data.week.push("周六");
          }
          if (ee == 7) {
            data.week.push("周日");
          }
        });
        this.header = data;
        await this.$store.dispatch("API_freight/getLclPlan", {
          pol_id: data.from_port,
          pod_id: data.dest_port,
          etd_start: this.$moment().unix(),
          is_hidden: 0
        }).then(res=>{
          if(res.success){
            this.schedulingList = res.data;
          }
        })
        this.componentSea();
      }
      let params2 = {
        cargo_type: 1,
        spell_type: 2,
        shipping_line: this.$route.query.shipping_line_id,
        freight_id: this.$route.query.id,
        volume: this.volume,
        weight: this.weight,
        piece_num: this.number,
        measurement_standard:this.MeasurementStandard // this.weight/1000
      };
      let result_1=await this.$store.dispatch("API_freight/getnewFreightInfo", params2)
      this.loading = false;
      if (result_1.success) {
        result_1.data.forEach((ee, index) => {
          ee.canCopy = true;
          ee.open = true;
          if (index > 0) {
            ee.open = false;
          }
        });
        this.costList = result_1.data;
        // console.log(this.costList);
      }
    },
    async exportFee(){
      this.loading = true;
      let params2 = {
        cargo_type: 1,
        spell_type: 2, 
        shipping_line: this.$route.query.shipping_line_id,
        freight_id: this.$route.query.id,
        volume: this.volume,
        weight: this.weight,
        piece_num: this.number,
        measurement_standard:this.MeasurementStandard // this.weight/1000
      };
      let result_1=await this.$store.dispatch("API_freight/downloadFreightInfo", params2)
      this.loading = false;
      if (result_1.success) {
        window.open(result_1.url);
        this.$message.success("导出成功");
      }
    },
    recalculate() {
      this.componentSea();
      this.getHeader();
    },
    componentSea() {
      let x =
        this.volume > this.weight / 1000 ? this.volume : this.weight / 1000;
      let y = Math.abs(this.header.price_lcl_dangerous);
      this.seaTotal = x * y;
    },
    handleLogin() {
      this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
    },
    handleBookingSpace() {
      let info = {
        from_port_name_en: this.header.from_port_name_en,
        from_port_name_zh: this.header.from_port_name_zh,
        from_port: this.header.from_port,
        dest_port_name_en: this.header.dest_port_name_en,
        dest_port_name_zh: this.header.dest_port_name_zh,
        dest_port: this.header.dest_port,
        freight_id:this.header.id
      };
      this.$router.push({
        path: "/bookingSpace",
        query: {
          info:JSON.stringify(info),
          activeName: "first",
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
.remarkBox{
  margin-top: 15px;
  .kuinput{
    margin-bottom: 12px;
  }
}
.myStyle_jt{
  height: 90px;
  position: relative;
  >div{
    width: 100%;
    color: #298df8;
  }
}
/deep/ .el-alert__title{
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
}
/deep/ .el-alert__icon{
  font-size: 22px;
}
/deep/ .el-alert--warning.is-light{
  background: none;
}
.unData{
  padding: 20px 0;
  font-size: 14px;
  font-weight: 400;
  color: #9A9DA2;
  text-align: center;
}
.text-mone{
  padding: 2px 0 0;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  color: #9A9DA2;
}
.moneyList {
  background: #fff;
  padding: 20px;
  .seaItem {
    margin-bottom: 20px;
    .itemTitleBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .btn {
        font-size: 16px;
        color: #999;
        cursor: pointer;
      }
    }
    .itemTitleBox {
      padding-bottom: 20px;
    }
    .el-icon-ship {
      display: inline-block;
      width: 20px;
      height: 20px;
      background: #ff7727;
      border-radius: 50%;
      text-align: center;
      line-height: 20px;
      font-size: 16px;
      color: #fff;
      margin-right: 10px;
    }
    .itemTitle {
      font-weight: 900;
      font-size: 18px;
    }
    .itemMOney {
      font-weight: 900;
      font-size: 18px;
    }
    .itemTips {
      font-size: 16px;
      color: #ff7727;
      padding-left: 10px;
    }
    .tableLine {
      display: flex;
      align-items: center;
      padding: 13px 0;
      border-bottom: 1px solid #F5F7FA;
    }
    .tableLine {
      .table1 {
        width: 250px;
      }
      .table2 {
        width: 150px;
      }
      .table3 {
        width: 150px;
      }
      .table4 {
        width: 150px;
      }
      .table5 {
        width: 150px;
      }
      .table6 {
        width: 300px;
        text-align: center;
      }
    }
    .tableTitle {
      background: #fbfcfa;
      border-bottom: 1px solid #ebeef5;
    }
  }
}
.seaTitle {
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
  font-size: 18px;
  font-weight: 900;
  margin-bottom: 20px;
}
.seaMoney {
  background: #fff;
  padding: 20px;
  margin-bottom: 10px;
  
  .searchBox {
    .flex {
      display: flex;
      align-items: center;
    }
  }
}
.chooseItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .itemtitle {
    font-size: 14px;
  }

  .switch {
    height: 50px;
  }
}
.booking {
  background-color: #91be42;
  border-color: #91be42;
}
.content_area {
  background: #f3f6f8;
  width: 1600px;
  min-height: 83vh;
  .mainbox{
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
}
.title {
  display: flex;
  height: 58px;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  .titleText {
    font-weight: 800;
    color: #1f292e;
    font-size: 16px;
    padding-left: 10px;
  }
  .booking {
    background-color: #91be42;
    border-color: #91be42;
  }
}
.header {
  width: 390px;
  margin-right: 10px;
  background: #fff;
  .isopen{
    background-color: rgb(242, 97, 97);
    color: white;
    text-align: center;
    line-height: 36px;
    margin-bottom: 20px;
    margin-top: -24px;
  }

  padding: 24px 0;
  .heraderTop {
    display: flex;
    flex-direction: column;
    .div1 {
      margin-top: -10px;
    }
    .div2 {
      max-width: 250px;
      margin: 0 10px;
      padding-top: 35px;
      
    }
    .div3 {
    }
    .portEn {
      color: #91be42;
      font-size: 24px;
      font-weight: 800;
    }
    .portZh {
      margin-top: 6px;
      font-size: 18px;
      color: rgba(0, 0, 0, 0.65);
    }
    .line {
      width: 150px;
      height: 1px;
      background: #cdcdcd;
      margin: 5px auto;
      position: relative;
      overflow: visible;
      .jiantou {
        position: absolute;
        color: #cdcdcd;
        right: -1px;
        top: -10px;
      }
    }
    div {
      overflow: hidden;
      white-space: nowrap !important;
      text-overflow: ellipsis;
    }
  }
  .heraderButtom {
    width: 100%;
    margin-top: 24px;
    display: flex;
    div {
      overflow: hidden;
      white-space: nowrap !important;
      text-overflow: ellipsis;
    }
    .div1 {
      width: 130px;
      color: rgba(0, 0, 0, 0.65);
    }
    .div2 {
      width: 100px;
      color: rgba(0, 0, 0, 0.65);
    }
    .div3 {
      width: 160px;
      text-align: right;
      padding-right: 8px;
      color: rgba(0, 0, 0, 0.65);
    }
    .div4 {
      width: 380px;
      color: rgba(0, 0, 0, 0.65);
      overflow: visible;
      white-space: normal !important;
      text-overflow: ellipsis;
      word-break: break-all;
    }
    span {
      color: #298df8;
      margin-left: 4px;
    }
  }
  .fleet{
    margin-top: 40px;
    .more{
      color: #298df8;
      font-size: 14px;
      padding-right: 10px;
      cursor: pointer;
    }
    .fleetlist{
      .item{
        display: flex;
        padding: 5px;
        &:first-child{
          font-weight: bold;
        }
        .tc{
          text-align: center;
        }
        .label{
          width: 25%;
          word-break: break-all;
          padding-right: 4px;
        }
      }
    }
  }
}
.cargoInfo{
  width: 1200px;
}
.content {
  background: #f3f6f8;
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px 20px;
  justify-content: space-between;
  //min-height: 500px;
  .item {
    width: 49%;
    background: #ffffff;
    margin-top: 16px;
    .costTitle {
      font-weight: 800;
      color: #1f292e;
      font-size: 16px;
      padding: 20px;
    }
    .line {
      height: 1px;
      width: 100%;
      background: #dcdfe6;
    }
    .costContent {
      padding: 20px;
      .costItem {
        display: flex;
        justify-content: space-between;
        .costItemTitle {
          color: #1f292e;
        }
        .costItemContent {
          color: #f7a350;
          font-size: 18px;
          font-weight: 800;
          span {
            font-size: 14px;
            color: #1f292e;
            font-weight: 400;
            margin-left: 4px;
          }
        }
        .itRemarks {
          margin-top: 6px;
          color: #999999;
        }
        .code {
          font-size: 12px;
          color: #999999;
        }
      }
      .constItem2 {
        margin-top: 20px;
        color: #f46401;
      }
    }
  }
}
.goLogin {
  text-align: center;
  color: #298df8;
  width: 100%;
  padding: 20px 0;
}
.item2Text {
  padding-left: 20px;
  padding-bottom: 20px;
  margin-top: 10px;
  color: #333333;
}
.pageHint {
  margin-top: 10px;
  margin-left: 15px;
  padding: 0px 0 15px 15px;
  color: red;
}
.unit{
  display: flex;
  .costItemContent{
    padding: 0 4px;
  }
}

</style>
